import React from 'react';

const LootboxUpdates = () => {
  return (
    <div className="p-6 bg-none mt-24 rounded-lg text-center">
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Join Us for XIDAR&apos;s Halloween Event! 🎃</h2>
      <p className="text-gray-600 mb-4">We&apos;ve put up some exciting prizes for the community, including:</p>
      <ul className="space-y-2 mb-6">
        <li className="text-lg">💰 <span className="font-medium text-gray-800">150 $BOBBY Tokens</span></li>
        <li className="text-lg">❄️ <span className="font-medium text-gray-800">1 Ice NFT</span></li>
        <li className="text-lg">🐾 <span className="font-medium text-gray-800">1 OG Bobby NFT</span></li>
      </ul>
    </div>
  );
};

export default LootboxUpdates;
